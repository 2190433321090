import {getIcon, getLocaleDate} from '@/constants';
import {useAppSelector} from '@/redux/hooks';
import {Link, usePage} from '@inertiajs/react';
import {truncate} from 'lodash';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';

export default function ({element}: any) {
    const {t} = useTranslation();
    const {
        currentLang,
        ziggy: {query},
    }: any = usePage().props;
    const {dir, isRTL} = useAppSelector((state) => state.locale);
    return (
        <div
            dir={dir}
            className="flex flex-col !w-auto h-auto border border-gray-200 bg-gray-25 rounded-3xl transform-all duration-300 hover:scale-95"
        >
            <Link
                href={`${route(`${currentLang}.frontend.course.show`, element.id)}?slug=${element.name}`}
                className="w-[100%] h-80"
            >
                <img
                    src={element.thumb}
                    className="w-[100%] h-80 rounded-t-3xl object-cover"
                />
            </Link>
            <Link
                href={`${route(`${currentLang}.frontend.course.show`, element.id)}?slug=${element.name}`}
                className="p-4 w-full flex flex-col justify-start items-center  truncate capitalize normal-text h-44"
            >
                <div className="flex flex-row w-full justify-between items-center  py-2">
                    <div className="flex flex-col gap-y-2">
                        <div className="text-lg text-prim-900">
                            {truncate(element.name, {length: 20})}
                        </div>
                        <div className="flex flex-col text-sm">
                            <div className="flex flex-row justify-start items-center gap-x-4">
                                <img
                                    src={element.teacher.thumb}
                                    className="w-6 h-6 rounded-full border border-gray-100"
                                    alt={element.name}
                                    loading="lazy"
                                />
                                <div className="text-prim-800">
                                    {truncate(
                                        `${element.teacher.title.abbreviation} ${element.teacher.name}`,
                                        {
                                            length: 20,
                                        },
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {element.on_sale && element.sale_price > 0 ? (
                            <div className="flex flex-col gap-y-2">
                                <div className="text-lg text-theme-700">
                                    {`${element.sale_price} ${t('kd')}`}
                                </div>
                                <div className="text-red-700 line-through text-md">{`${element.total_price} ${t('kd')}`}</div>
                            </div>
                        ) : (
                            <div className="text-lg text-theme-700">{`${element.total_price} ${t('kd')}`}</div>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-3 w-full py-2 gap-2">
                    <div className="flex flex-row gap-x-2 justify-start items-center">
                        <div>
                            <ReactSVG
                                src={getIcon('item.svg')}
                                className="h-6 w-6 text-theme-700"
                            />
                        </div>
                        <div className="text-xs">{`${element.is_ticket ? t('consultancy') : t('course')}`}</div>
                    </div>
                    <div className="flex flex-row gap-x-2 justify-start items-center">
                        <div>
                            <ReactSVG
                                src={getIcon('online.svg')}
                                className="h-6 w-6 text-theme-700"
                            />
                        </div>
                        <div className="text-xs">{`${t(element.group)}`}</div>
                    </div>

                    {/* {element.group === 'attendance' ? (
                        <div className="flex flex-row gap-x-2 justify-start items-center w-5/6 truncate">
                            <div>
                                <ReactSVG
                                    src={getIcon('hall.svg')}
                                    className="h-6 w-6 text-theme-700"
                                />
                            </div>
                            <div className="text-xs">{`${t('hall')} ${t(element.hall.name)}`}</div>
                        </div>
                    ) : null} */}
                    {element.group === 'attendance' ? (
                        <div className="flex flex-row gap-x-2 justify-start items-center">
                            <div>
                                <ReactSVG
                                    src={getIcon('calender.svg')}
                                    className="h-6 w-6 text-theme-700"
                                />
                            </div>
                            <div className="text-xs">{`${getLocaleDate(element.start_date, isRTL)}`}</div>
                        </div>
                    ) : null}
                    <div className="flex flex-row gap-x-2 justify-start items-center">
                        <div>
                            <ReactSVG
                                src={getIcon('book.svg')}
                                className="h-6 w-6 text-theme-700"
                            />
                        </div>
                        <div className="text-xxs">{`${t('session_no')} (${element.sessions.length})`}</div>
                    </div>
                </div>
            </Link>
            <div className="bottom-0 flex  w-full  bg-gray-25 rounded-b-3xl !h-0">
                <div className="grid grid-cols-3 w-full divide-x rtl:divide-x-reverse border-t border-t-0 hidden">
                    <div className="col-span-1 flex justify-center items-center py-2 normal-text text-xs truncate capitalize">
                        {element.subject.name}
                    </div>
                    <div className="col-span-1 flex justify-center items-center py-2 normal-text text-xs truncate capitalize">
                        {element.subject.grade.stage.name}
                    </div>
                    <div className="col-span-1 flex justify-center items-center py-2 normal-text text-xs truncate capitalize">
                        {element.subject.grade.name}
                    </div>
                </div>
            </div>
        </div>
    );
}
